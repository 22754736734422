import React, { useCallback } from 'react';
import { Button, InputGroup, Popover, Tag } from '@blueprintjs/core';

import { useOdinSearchActions, useOdinSearchState } from "../../../modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/provider";

type AppliedFiltersProps = {
  filterStyle?: React.CSSProperties;
}

export const AppliedFilters: React.FC<AppliedFiltersProps> = ({ filterStyle }) => {
  // Context state
  const { selectedParams } = useOdinSearchState();
  const { removeFilter, saveParams } = useOdinSearchActions();

  // Local state
  const [filterName, setFilterName] = React.useState<string>('');

  // Event handlers
  const handleSaveFilterClick = useCallback(() => {
    if (!selectedParams) return;

    saveParams({ ...selectedParams, name: filterName });
  }, [selectedParams, saveParams, filterName]);

  const filterValues = selectedParams ? selectedParams.filters : [];

  if (filterValues.length === 0) return null;

  return (
    <>
      {filterValues.map((filter) => (
        <Tag
          intent="success"
          minimal
          key={`${filter.columnName}-${filter.operator}-${filter.value}`}
          style={filterStyle}
          onRemove={() => {
            removeFilter(filter);
          }}
        >
          {filter.columnName}: {filter.value}
        </Tag>
      ))}
      <Popover
        content={
          <InputGroup
            placeholder="Filter Name"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            rightElement={
              <Button
                icon="floppy-disk"
                text="Save"
                disabled={!filterName}
                onClick={handleSaveFilterClick}
              />
            }
          />
        }
      >
        <Button
          icon="floppy-disk"
          style={{ borderRadius: 5 }}
        />
      </Popover>
    </>
  );
}