import { Button, MenuItem, NonIdealState, Slider } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { getInitialsFromName } from '../../../../../../shared/helpers/UIHelpers';

import SchedulingUserTimeline from './SchedulingUserTimeline';
import './styles.scss';
import { ALL_TIME_BLOCKS, TScheduleUser } from './types';
import {
  caseManagementSchedulingReducer,
  CaseManagementSchedulingReducerInitialState,
  ICaseManagementSchedulingReducer,
} from './store/reducer';
import SchedulingUserDialog from './SchedulingUserDialog';
import {
  CASE_MANAGEMENT_SCHEDULING_IS_LOADING_LIST_VIEW,
  CASE_MANAGEMENT_SCHEDULING_SET_LIST_VIEW_DATA,
  CASE_MANAGEMENT_SCHEDULING_SET_ZOOM,
} from './store/constants';
import { httpGet } from '../../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import { connect } from 'react-redux';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getErrorMessage } from '../../../../../ControlPanelModule/utils/errors';

interface Props {
  alertMessage: (params: { body: string; type: string }) => void;
}

export type TCaseManagementSchedulingContext = {
  state: ICaseManagementSchedulingReducer;
  dispatch: React.Dispatch<any>;
};

export const CaseManagementSchedulingContext =
  React.createContext<TCaseManagementSchedulingContext>({
    state: CaseManagementSchedulingReducerInitialState,
    dispatch: () => {},
  });

const CaseManagementScheduling: React.FC<Props> = (props: Props) => {
  const { alertMessage } = props;

  const [state, dispatch] = useReducer(
    caseManagementSchedulingReducer,
    CaseManagementSchedulingReducerInitialState,
  );

  const [departmentList, setDepartmentList] = useState<DbRecordEntityTransform[]>([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState<boolean>(false);
  const [selectedDepartment, setSelectedDepartment] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  // Fetch department list on component mount
  useEffect(() => {
    fetchDepartmentList();
  }, []);

  // Fetch data on department update
  useEffect(() => {
    if (selectedDepartment) {
      fetchUsersAndTimeBlocks();
    }
  }, [selectedDepartment]);

  const fetchDepartmentList = async () => {
    setIsLoadingDepartments(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/departments?size=10000`);
      const departments: any[] = res.data.data || [];
      setDepartmentList(departments);
      setIsLoadingDepartments(false);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve department list. ' + message, type: 'error' });
      setDepartmentList([]);
      setIsLoadingDepartments(false);
    }
  };

  const fetchUsersAndTimeBlocks = async () => {
    dispatch({ type: CASE_MANAGEMENT_SCHEDULING_IS_LOADING_LIST_VIEW, payload: true });

    try {
      // const res = await httpGet(`IdentityModule/v1.0/schedules/time-blocks?departmentId=${}`);
      const res = await httpGet(`IdentityModule/v1.0/schedules/time-blocks`);
      const users = res.data?.data || [];
      dispatch({ type: CASE_MANAGEMENT_SCHEDULING_IS_LOADING_LIST_VIEW, payload: false });

      console.log('🛠️ debug: Scheduling List', users);

      dispatch({ type: CASE_MANAGEMENT_SCHEDULING_SET_LIST_VIEW_DATA, payload: users });
    } catch (err: any) {
      const error = err.response ? err.response.data : undefined;
      dispatch({ type: CASE_MANAGEMENT_SCHEDULING_IS_LOADING_LIST_VIEW, payload: false });
      alertMessage({
        body: (error && error.message) || 'Error fetching the scheduling list',
        type: 'error',
      });
    }
  };

  const agentColumn = useRef<HTMLDivElement>(null);
  const contentRow = useRef<HTMLDivElement>(null);
  const timelineColumn = useRef<HTMLDivElement>(null);
  const timelineHeader = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const syncScroll = (
    sourceDiv: React.RefObject<HTMLDivElement>,
    targetDiv: React.RefObject<HTMLDivElement>,
  ) => {
    if (sourceDiv.current && targetDiv.current) {
      targetDiv.current.scrollLeft = sourceDiv.current.scrollLeft;
    }
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (timelineHeader.current && timelineColumn.current) {
      setIsDragging(true);
      setStartX(e.pageX - timelineColumn.current.offsetLeft);
      setScrollLeft(timelineColumn.current.scrollLeft);
      // Prevent text selection
      timelineHeader.current.style.cursor = 'move';
      timelineHeader.current.style.userSelect = 'none';
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !contentRow.current || !timelineColumn.current) return;
    e.preventDefault(); // Prevent default behavior like text selection
    const x = e.pageX - timelineColumn.current.offsetLeft;
    const walkX = (x - startX) * 2; // Adjust X scrolling speed
    timelineColumn.current.scrollLeft = scrollLeft - walkX;
  };

  const onMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  const getTimeSlotWidth = () => {
    switch (state.horizontalZoom) {
      case 0:
        return 40;
      case 25:
        return 50;
      case 50: // 75,0 %
        return 60;
      case 75: // 87,5 %
        return 70;
      case 100:
        return 80; // 100,0 %
      default:
        return 80;
    }
  };

  const getTimeSlotRowWidth = () => {
    switch (state.horizontalZoom) {
      case 0:
        return 1933;
      case 25:
        return 2417;
      case 50:
        return 2900;
      case 75:
        return 3384;
      case 100:
        return 3867;
      default:
        return 3867;
    }
  };

  let DEPARTMENTS: any[] = departmentList.map((dp: DbRecordEntityTransform) => ({
    id: dp.id,
    name: dp.name,
    key: dp.id,
    disabled: false,
  }));

  const renderDepartmentSelectItem: ItemRenderer<any> = (
    item,
    { handleClick, handleFocus, modifiers },
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.key}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="menuitem"
        text={item.name}
      />
    );
  };

  const handleDepartmentSelect = (id: string) => {
    const dep = DEPARTMENTS.find((d) => d.id === id);
    setSelectedDepartment(dep);
  };

  return (
    <CaseManagementSchedulingContext.Provider value={{ state, dispatch }}>
      <SchedulingUserDialog />

      {/* Heading Row */}
      <Row align="middle" justify="space-between">
        <Col>
          <h1 style={{ margin: 0 }}>Scheduling</h1>
        </Col>
        {/* Company select */}
        <Col>
          <Select
            activeItem={DEPARTMENTS.find((d) => d.id === selectedDepartment?.id)}
            items={DEPARTMENTS}
            itemRenderer={renderDepartmentSelectItem}
            onItemSelect={(e: any) => {
              handleDepartmentSelect(e.id);
            }}
            filterable={false}
          >
            <Button
              loading={isLoadingDepartments}
              disabled={isLoadingDepartments}
              style={{ borderRadius: 5 }}
              alignText="left"
              fill
              text={selectedDepartment?.name || 'Select Department'}
              rightIcon="caret-down"
            />
          </Select>
        </Col>
      </Row>

      {/* Header Row */}
      <Row
        style={{
          marginTop: 30,
          background: '#fff',
        }}
      >
        {/* Agent Header */}
        <Col
          span={4}
          style={{
            height: '100%',
            zIndex: 9,
            boxShadow: 'rgba(0, 0, 0, 0.06) 5px 1px 5px -2px',
          }}
        >
          <Row className="schedulingLeftSidebar" justify="space-between">
            <Col>
              <span style={{ fontSize: '1.1em' }}>Agent</span>
            </Col>
          </Row>
        </Col>

        {/* Timeline Header */}
        <Col
          span={20}
          className="schedulingRightSidebar"
          style={{ overflowX: 'auto' }}
          ref={timelineHeader}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUpOrLeave}
          onMouseLeave={onMouseUpOrLeave}
          onScroll={() => syncScroll(timelineHeader, timelineColumn)}
        >
          <Row style={{ width: getTimeSlotRowWidth(), paddingLeft: 5 }}>
            {ALL_TIME_BLOCKS.map((time, i) => (
              <Col
                key={time}
                style={{
                  width: getTimeSlotWidth(),
                  fontWeight: 600,
                  fontSize: state.horizontalZoom < 50 ? '0.8em' : '1em',
                  padding: '5px 0',
                  margin: '10px 0',
                  textAlign: 'center',
                  border: '1px solid transparent',
                  borderRight:
                    i === ALL_TIME_BLOCKS.length - 1
                      ? '1px solid transparent'
                      : '1px solid #D5D5D5',
                }}
              >
                {time}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      {/* Content Row */}
      <Row
        ref={contentRow}
        style={{
          height: 'calc(100vh - 240px)',
          overflowY: 'scroll',
          borderBottom: '1px solid #F2F2F2',
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        {/* Agent Column */}
        <Col span={4} ref={agentColumn} style={{ boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)' }}>
          {state.schedulingUsers.map((user: TScheduleUser, i: number) => (
            <Row
              key={i}
              style={{ padding: 8, border: '1px solid #F2F2F2', borderRight: 0, borderTop: 0 }}
              align="middle"
            >
              <Col span={22}>
                <Row align="middle">
                  <Col>
                    <div
                      style={{
                        background: '#F5F5F5',
                        padding: '6px 5px',
                        width: 30,
                        height: 30,
                        borderRadius: 50,
                        textAlign: 'center',
                      }}
                    >
                      {getInitialsFromName(`${user.firstName} ${user.lastName}`)}
                    </div>
                  </Col>
                  <Col
                    key={i}
                    style={{ paddingLeft: 8 }}
                  >{`${user.firstName} ${user.lastName}`}</Col>
                </Row>
              </Col>
              <Col span={2}>
                {/*<span>*/}
                {/*  <i className="bi bi-three-dots-vertical" />*/}
                {/*</span>*/}
              </Col>
            </Row>
          ))}
        </Col>

        {/* Agent Timeline */}
        <Col
          ref={timelineColumn}
          onScroll={() => syncScroll(timelineColumn, timelineHeader)}
          span={20}
          style={{
            overflowX: 'scroll',
            overflowY: 'hidden',
            borderRight: '1px solid #F2F2F2',
          }}
        >
          {!selectedDepartment && (
            <Row style={{ height: '100%' }}>
              <NonIdealState
                title="No Department Selected"
                icon="search"
                description="Please select department to fetch the list of users and time blocks."
              />
            </Row>
          )}

          {selectedDepartment &&
            state.schedulingUsers.map((user: TScheduleUser, i: number) => (
              <Row
                key={i}
                align="middle"
                style={{
                  width: getTimeSlotRowWidth() - (state.horizontalZoom === 0 ? 8 : 12),
                  overflowY: 'hidden',
                  height: 47,
                  paddingLeft: 5,
                  borderBottom: '1px solid #F2F2F2',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Col span={24} style={{ height: 47 }}>
                  <SchedulingUserTimeline user={user} />
                </Col>
              </Row>
            ))}
        </Col>
      </Row>

      {/* Footer */}
      <Row justify="end" style={{ padding: '3px 5px' }}>
        <Col span={3} style={{ marginTop: 5 }}>
          <Slider
            value={state.horizontalZoom}
            min={0}
            max={100}
            labelStepSize={25}
            stepSize={25}
            onChange={(e: any) => {
              dispatch({ type: CASE_MANAGEMENT_SCHEDULING_SET_ZOOM, payload: e });
            }}
          />
        </Col>
      </Row>
    </CaseManagementSchedulingContext.Provider>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementScheduling);
